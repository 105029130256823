import axios from 'axios';
import Vue from 'vue'
import { Modal } from "ant-design-vue";

const envUrls = {
    'yansi.cn': 'https://api.yansi.cn',
    'www.yansi.cn': 'https://api.yansi.cn',
    'pre.yansi.cn': 'https://api-pre.yansi.cn',
    'uat.yansi.cn': 'https://api-uat.yansi.cn',
    '192.168.23.1': 'http://192.168.23.1:8084',
    'localhost:8084': 'http://localhost:8084'
};

const baseUrl = envUrls[window.location.host] || envUrls["pre.yansi.cn"]

let flag = false;
const jumpUrl = (path) => {
    if (
        window.location.host == "yansi.cn" ||
        window.location.host == "www.yansi.cn" || window.location.host == 'pre.yansi.cn' || window.location.host == "uat.yansi.cn"
    ) {
        window.location.href = `https://${window.location.host}/#/${path}`;
    } else if (window.location.host == 'localhost:8084') {
        window.location.href = `http://${window.location.host}/#/${path}`;
    } else {
        window.location.href = `http://${window.location.host}/expert/#/${path}`;
    }
}
const req = (method, url, params, headers = {}) => {
    return axios({
        method: method,
        url: baseUrl + url, // 测试
        headers: {
            'Content-Type': 'application/json;charset:utf-8',
            Authorization: window.localStorage.getItem('token'),
            ...headers
        },
        data: params,
    }).then(res => {
        if (res.status === 200) {
            if (res.data.code == 401) {
                Vue.prototype.$message.error("登录状态已过期，请重新登录。");
                window.localStorage.clear()
                jumpUrl('login?type=1')
                return
            }
            if (res.data.code == 21103) {
                if (!flag) {
                    Modal.warning({
                        title: "您已评阅的部分论文需要专家签名，研斯数据承诺将对您的签名进行保密，只应用在评阅意见书存档文件中。",
                        okText: "设计电子签名",
                        keyboard: false,
                        onOk() {
                            flag = false
                            jumpUrl('dianZiQianMing')
                        },
                    });
                }
                flag = true;
            }
            if (res.data.code !== 200) {
                console.log('res :>> ', res);
            }
            return res.data
        } else {
            return false
        }
    });
};

export default req
